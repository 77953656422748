import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { SavingsTable } from './savings-table/savings-table.component';
import { Subject, Subscription } from 'rxjs';
import { SaveEventName } from '@core/enums/save-event-name.enum';
import { ProductService } from '@core/services';

@Component({
    selector: 'app-saving-contributions-step',
    templateUrl: './saving-contribution.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        CommonModule,
        MatIcon,
        MatTableModule,
        SavingsTable,
    ],
    styleUrls: ['./saving-contribution.component.css'],
})
export class SavingContributionsStepComponent implements OnInit, OnDestroy {
    savingcat1: boolean = true;
    savingcat2: boolean = true;
    savingcat3: boolean = true;
    @Input()
    offerId: number;
    @Input() isPreviewMode: boolean;
    @Input() isEditable: boolean;
    @Input() isPlanEditable: boolean;
    @Input() categories: number;
    @Input() saveTab: Subject<SaveEventName>;
    tableUpdated: Subject<SaveEventName> = new Subject();
    updateTable: Subject<SaveEventName> = new Subject();
    tableUpdatedCounter = 0;

    private _subscriptions: Subscription[] = [];

    constructor(private _productService: ProductService) {}

    ngOnInit(): void {
        const subs1 = this.saveTab.subscribe((value) => {
            this.updateTable.next(value)
        });

        const subs2 = this.tableUpdated.subscribe((value) => {
            if(this.tableUpdatedCounter != this.categories - 1) {
                this.tableUpdatedCounter += 1;
                return;
            }
            this._productService.updatedForm(SaveEventName.TAB_UPDATED);
        });
        
        this._subscriptions.push(subs1);
        this._subscriptions.push(subs2);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    showCategory(event: boolean, savingcat: string) {
        switch (savingcat) {
            case 'savingcat1':
                this.savingcat1 = event;
                break;
            case 'savingcat2':
                this.savingcat2 = event;
                break;
            case 'savingcat3':
                this.savingcat3 = event;
                break;
        }
    }
}
