import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import PlanFromOffer from '@core/types/plan';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { RiskBenefitsComponent } from './risk-benefits/risk-benefits.component';
import { SavingContributionsStepComponent } from './saving-contribution/saving-contribution.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { SaveEventName } from '@core/enums/save-event-name.enum';

@Component({
    selector: 'app-configure-plan',
    standalone: true,
    imports: [
        MatTabsModule,
        GeneralInfoComponent,
        RiskBenefitsComponent,
        SavingContributionsStepComponent,
        TranslateModule,
    ],
    templateUrl: './configure-plan.component.html',
    styleUrl: './configure-plan.component.css',
})
export class ConfigurePlanComponent implements OnInit, OnDestroy {
    @Input() isPreviewMode: boolean;
    @Input() isEditable: boolean;
    @Input() isPlanEditable: boolean;
    @Input()
    currentPlan: PlanFromOffer;
    categories = 3;
    @Input()
    offerId: number;
    @Input() savePlanOnNextBtn: Subject<SaveEventName>;
    saveGeneralInfoTab: Subject<SaveEventName> = new Subject();
    saveRiskTab: Subject<SaveEventName> = new Subject();
    saveSavingsTab: Subject<SaveEventName> = new Subject();
    _subscriptions = [];
    currentTab = 0;

    constructor() {}

    ngOnInit() {
        const sub1 = this.savePlanOnNextBtn.subscribe((value) => {
            this.saveCurrentTab(SaveEventName.NEXT_BTN_SAVE);
        });
        this._subscriptions.push(sub1);

        this.categories = this.currentPlan?.BSKAT.value ? parseInt(this.currentPlan?.BSKAT.value) : 3;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    handleNumberOfCategories(categories: number) {
        this.categories = categories;
    }

    onTabChange(event: MatTabChangeEvent) {
        this.saveCurrentTab(SaveEventName.SWITCH_TAB_SAVE);
        this.currentTab = event.index;
    }

    saveCurrentTab(value) {
        switch(this.currentTab){
            case 2:
                this.saveSavingsTab.next(value);
                break;
            case 1:
                this.saveRiskTab.next(value);
                break;
            case 0:
                this.saveGeneralInfoTab.next(value);
                break;
        }
    }
}
